<template>
  <b-tooltip multilined>
    <template v-slot:content>
      Significance: 1 % (<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>), 5 %(<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star-o"></i>) or 10 % (<i class="fa fa-star"></i><i class="fa fa-star-o"></i><i class="fa fa-star-o"></i>)
    </template>
    <div class="block">
      <b-icon
        v-for="(star,index1) in score"
        :key="index1"
        type="is-primary"
        size="is-small"
        icon="star"/>
      <b-icon
        v-for="(star,index2) in maxScore-score"
        :key="score+index2"
        type="is-primary"
        size="is-small"
        icon="star-o"/>
    </div>
  </b-tooltip>
</template>

<script>
export default {
  name: 'StatisticalTestStars',
  props: {
    score: {
      type: Number,
      default: 0
    },
    maxScore: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>
.b-tooltip.is-primary .tooltip-content {
  background: #DEF4FD;
  color: #1896E1;
}
.b-tooltip.is-top.is-primary .tooltip-content::before {
  border-top-color: #DEF4FD;
}
</style>
